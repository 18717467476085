import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {joinGameLobby} from "../UEWebRTC";

export default function BulkFiestamal() {
  const [fiestamalCount, setFiestamalCount] = useState(0);
  const [roomCode, setRoomCode] = useState(0);
  const handleFiestamalCountChange = (e) => {
    setFiestamalCount(parseInt(e.target.value));
  };

  const handleRoomCodeChange = (e) => {
    setRoomCode(e.target.value);
  };

  // TODO: Rewrite this to be less garbage
  const handleJoinClicked = (e) => {
    let i = 1;
    function joinLobbyNow() {
      try {
        joinGameLobby(roomCode, `fiestamal${i}`)
        if (i < fiestamalCount) {
          setTimeout(joinLobbyNow, 500);
          i++;
        }
      } catch (_) {

      }
    }
    joinLobbyNow();
  }

  return (
    <>
      <div style={{
        width: "50%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "absolute",
        left: "50%",
        top: "10%",
        translate: "-50% -50%",
      }}>
        <TextField
          id="roomCode"
          label="Enter Room Code"
          variant="filled"
          size="Large"
          fullWidth
          color="secondary"
          onChange={handleRoomCodeChange}
        />
        <TextField
          id="fiestamalCount"
          label="Enter The Amount of Fiestamals to Spawn"
          variant="filled"
          size="Large"
          fullWidth
          color="secondary"
          onChange={handleFiestamalCountChange}
        />
        <Button
          variant="contained"
          disableElevation
          fullWidth
          sx={{
            bgcolor: "#fa6666",
            "&:hover": { bgcolor: "#e63939" },
          }}
          onClick={handleJoinClicked}
        >
          Spawn Fiestamals
        </Button>
      </div>
    </>
  )
}