import DrawCSS from "./Draw.module.css";
import DRAW_IMG from "./Images/DRAW.png";
import FIRE_IMG from "./Images/FIRE.png";
import DUCK_IMG from "./Images/DUCK.png";
import LEFT_ARROW_IMG from "./Images/LEFT_ARROW.png";
import RIGHT_ARROW_IMG from "./Images/RIGHT_ARROW.png";
import DUEL from "./Images/DUEL.png";
import { useContext, useEffect, useState } from "react";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";

const Draw = () => {
  const [roomConnection] = useContext(RoomConnectionContext);

  const playerType = {
    Instigator: 0,
    Duelist: 1,
    Bystander: 2,
    Referee: 3,
    Gunslinger: 4,
  };

  const [currentPlayerType, setCurrentPlayerType] = useState(
    playerType.Duelist
  );
  const [mainButton, setMainButton] = useState(DRAW_IMG);

  const [canPress, setCanPress] = useState(true);

  useEffect(() => {
    roomConnection?.addCustomListener("PlayerData", onUpdatePlayerData, "draw");
    roomConnection?.dispatchQueuedEvents("draw");
    return () => {
      roomConnection?.removeCustomListener("PlayerData", onUpdatePlayerData);
    };
  }, [roomConnection]);

  useEffect(() => {
    if (
      currentPlayerType === playerType.Instigator ||
      currentPlayerType === playerType.Referee
    ) {
      setMainButton(DRAW_IMG);
    } else if (currentPlayerType === playerType.Duelist) {
      setMainButton(FIRE_IMG);
    } else if (currentPlayerType === playerType.Bystander) {
      setMainButton(DUCK_IMG);
    } else if (currentPlayerType === playerType.Gunslinger) {
      setMainButton(DUEL);
    }
  }, [currentPlayerType]);

  async function onUpdatePlayerData(event) {
    let playerData = event.detail;
    if (playerData?.playerType !== undefined)
      setCurrentPlayerType(playerData.playerType);
    if (playerData?.canPress !== undefined) setCanPress(playerData.canPress);

    console.log("playerData: ", playerData);
  }

  const handleMainClick = () => {
    if (!canPress) return;
    setCanPress(false);

    if (currentPlayerType === playerType.Instigator) {
      roomConnection.sendButtonClickedEvent("draw");
    } else if (currentPlayerType === playerType.Duelist) {
      roomConnection.sendButtonClickedEvent("fire");
    } else if (currentPlayerType === playerType.Bystander) {
      roomConnection.sendButtonClickedEvent("duck");
    } else if (currentPlayerType === playerType.Referee) {
      roomConnection.sendButtonClickedEvent("duelDraw");
    } else if (currentPlayerType === playerType.Gunslinger) {
      roomConnection.sendButtonClickedEvent("duelShot");
    }
  };

  return (
    <>
      <div className={DrawCSS["wrapper-background"]}>
        <div className={DrawCSS.items}>
          <img
            id="main_button"
            src={mainButton}
            alt="MAIN BUTTON IMG"
            className={
              canPress ? DrawCSS["imageEnabled"] : DrawCSS["imageDisabled"]
            }
            onClick={handleMainClick}
            style={{
              margin: "0px 0px 50px 0px",
            }}
          />
          {currentPlayerType === playerType.Instigator && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                id="left_button"
                src={LEFT_ARROW_IMG}
                alt="LEFT BUTTON IMG"
                className={
                  canPress ? DrawCSS["imageEnabled"] : DrawCSS["imageDisabled"]
                }
                onClick={() => {
                  // Left button click
                  if (!canPress) return;
                  roomConnection.sendButtonClickedEvent("left");
                }}
                style={{
                  width: "35%",
                  margin: "0px 200px 0px 0px",
                }}
              />
              <img
                id="right_button"
                src={RIGHT_ARROW_IMG}
                alt="RIGHT BUTTON IMG"
                className={
                  canPress ? DrawCSS["imageEnabled"] : DrawCSS["imageDisabled"]
                }
                onClick={() => {
                  // Right button click
                  if (!canPress) return;
                  roomConnection.sendButtonClickedEvent("right");
                }}
                style={{
                  width: "35%",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Draw;
