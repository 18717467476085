let roomConnection;

export function setupRoomConnection(roomConnectionIn, navigate, location) {
  roomConnection = roomConnectionIn;
  // Adds a message queue to room connection in case a message came from unreal before a component listener was added
  roomConnection.messageQueue = [];
  roomConnection.eventListeners = new Map();
  roomConnection.currentMinigame = "";
  roomConnection.onmessage = async (message) => {
    const jsonMsg = decryptMessage(message);
    const jsonData = jsonMsg.data;
    tryDispatchEvent(
      jsonMsg.type,
      new CustomEvent(jsonMsg.type, { detail: jsonData })
    );
    switch (jsonMsg.type) {
      case "ChangeMinigame":
        // If we're not currently in the play page, navigate to it
        if (location.pathname !== "/play") {
          navigate("/play", {
            state: { minigame: jsonData.minigame },
            replace: true,
          });
        }
        break;
      case "Disconnect":
        console.log("Player kicked");
        roomConnection.close();
        navigate("/", {
          replace: true
        });
        break;
    }
  };

  Object.assign(roomConnection, {
    sendButtonClickedEvent,
    addCustomListener,
    removeCustomListener,
    sendCosmeticChangedEvent,
    dispatchQueuedEvents
  });
}

// Function to clean messages from unreal
function decryptMessage(rawMsg) {
  let textDecoder = new TextDecoder("utf-8");
  let rawString = textDecoder.decode(rawMsg.data);

  // eslint-disable-next-line no-control-regex
  let cleanString = rawString.replace(/\x00/g, "");
  return JSON.parse(cleanString);
}

function sendButtonClickedEvent(id) {
  let eventObject = {
    type: "Button",
    data: {
      id,
    },
  };
  roomConnection.send(JSON.stringify(eventObject));
}

function sendCosmeticChangedEvent(cosmeticObject) {
  let eventObject = {
    type: "CosmeticsChanged",
    data: {
      color: {
        colorOne: cosmeticObject.colorOne,
        colorTwo: cosmeticObject.colorTwo,
        colorThree: cosmeticObject.colorThree,
      },
    },
  };
  roomConnection.send(JSON.stringify(eventObject));

  roomConnection.currentColor = cosmeticObject;
}

function addCustomListener(eventType, eventFunction, minigame) {
  // Add new map for eventListener if it doesn't already exist
  let eventTypeMap = roomConnection.eventListeners.get(eventType);
  if (!eventTypeMap) {
    eventTypeMap = new Map();
    roomConnection.eventListeners.set(eventType, eventTypeMap);
  }

  eventFunction.functionId = crypto.randomUUID();
  const wrappedEventFunction = (event) => {
    if (minigame && minigame !== event?.detail?.target) {
      console.warn("event minigame", event?.detail?.target, "is not equal to listener minigame", minigame, event);
      console.warn(eventFunction);
      return;
    }
    eventFunction(event);
  };
  eventTypeMap.set(eventFunction.functionId, wrappedEventFunction);
  roomConnection.addEventListener(eventType, wrappedEventFunction);
}

function removeCustomListener(eventType, eventFunction) {
  let eventTypeMap = roomConnection.eventListeners.get(eventType);
  let wrappedEventFunction = eventTypeMap?.get(eventFunction.functionId);
  if (!wrappedEventFunction) return;
  eventTypeMap.delete(eventFunction.functionId);
  roomConnection.removeEventListener(eventType, wrappedEventFunction);
}

// Tries to dispatch a message to room connection for a specified type.
// If there are no listeners for that type in room connection, it will add it to the message queue.
function tryDispatchEvent(eventType, event) {
  let eventTarget = event?.detail?.target;
  let eventTypeMap = roomConnection.eventListeners.get(eventType);
  const noQueueMessageTypes = ["ChangeMinigame", "Disconnect"];
  if (noQueueMessageTypes.includes(eventType) ||
     (roomConnection.currentMinigame === eventTarget && eventTypeMap?.size)) {
    roomConnection.dispatchEvent(event);
  } else {
    console.warn("Added event", event, "to queue.");
    roomConnection.messageQueue.push({
      type: eventType,
      event: event
    });
  }
}

// Goes through the queue and dispatches any events that are currently being listened to.
// Leaves the rest of the events in the queue.
function dispatchQueuedEvents(minigame) {
  let remainingEvents = [];
  roomConnection.currentMinigame = minigame;
  for (const event of roomConnection.messageQueue) {
    let eventTypeMap = roomConnection.eventListeners.get(event.type);
    if (event.event?.detail?.target !== minigame || !eventTypeMap?.size) {
      remainingEvents.push(event.event);
      console.warn("Not dispatching event:", event.event);
    } else {
      roomConnection.dispatchEvent(event.event);
    }
    roomConnection.messageQueue = remainingEvents;
  }
}