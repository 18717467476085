import LookUpCSS from "./LookUp.module.css";

const LookUp = () => {
  return (
    <div className={LookUpCSS["wrapper-background"]}>
      <div className={LookUpCSS.items}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "48px",
            color: "white",
          }}
        >
          Look up!
        </h1>
        <h3
          style={{
            textAlign: "center",
            fontSize: "32px",
            color: "white",
          }}
        >
          There's something happening on the big screen!
        </h3>
      </div>
    </div>
  );
};

export default LookUp;
