import JoinRoomCSS from "./JoinRoom.module.css";
import { Grid, TextField, Button } from "@mui/material";
import FiestaLogo from "../../Images/fiesta_logo.png";
import { joinGameLobby } from "../../UEWebRTC";
import { useNavigate, useLocation } from "react-router-dom";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";
import { useState, useContext } from "react";
import { setupRoomConnection } from "../../RoomConnection/RoomConnectionHandler";

const JoinRoom = () => {
  const [nicknameInput, setNicknameInput] = useState();
  const [codeInput, setCodeInput] = useState();
  const [roomConnection, setRoomConnection] = useContext(RoomConnectionContext);
  const navigate = useNavigate();
  const location = useLocation();

  const maxNicknameLength = 15;

  const handleNicknameChange = (e) => {
    setNicknameInput(e.target.value);
  };

  const handleCodeChange = (e) => {
    setCodeInput(e.target.value);
  };

  const handleJoinGame = () => {
    if (roomConnection?.readyState === "open") return;

    let nickname = nicknameInput;
    if (nickname.toLowerCase() == "a pimp named slickback") {
    } else if (nickname.length >= maxNicknameLength) {
      let trimmedName = nickname.substring(0, maxNicknameLength + 1);
      nickname = trimmedName + "...";
    }

    joinGameLobby(codeInput, nickname)
      .then((result) => {
        setupRoomConnection(result, navigate, location);
        setRoomConnection(result);
        result.nickname = nickname;
        result.roomCode = codeInput;
        navigate("/play");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={JoinRoomCSS["wrapper-background"]}>
        <div className={JoinRoomCSS.items}>
          <img
            src={FiestaLogo}
            alt="LOGO"
            style={{
              margin: "0px 0px 150px 0px",
            }}
          />

          <div
            className={JoinRoomCSS.formSection}
            style={{
              margin: "0px 0px 50px 0px",
            }}
          >
            <TextField
              id="nickname"
              label="Enter Nickname"
              variant="filled"
              size="Large"
              fullWidth
              color="secondary"
              sx={{
                "& .MuiFilledInput-root": {
                  color: "#000",
                  "&:before": {
                    borderColor: "#d86ff2",
                    borderWidth: "2px",
                  },
                },
                "& .MuiInputLabel-filled": {
                  color: "#2e2e2e",
                },
              }}
              onChange={handleNicknameChange}
            />

            <TextField
              id="code"
              label="Enter Room Code"
              variant="filled"
              fullWidth
              color="secondary"
              sx={{
                "& .MuiFilledInput-root": {
                  color: "#000",
                  "&:before": {
                    borderColor: "#d86ff2",
                    borderWidth: "2px",
                  },
                },
                "& .MuiInputLabel-filled": {
                  color: "#2e2e2e",
                },
              }}
              onChange={handleCodeChange}
            />

            <Button
              variant="contained"
              disableElevation
              fullWidth
              size="large"
              sx={{
                bgcolor: "#fa6666",
                "&:hover": { bgcolor: "#e63939" },
              }}
              onClick={handleJoinGame}
            >
              Join Game as Guest
            </Button>

            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                bgcolor: "#ffad54",
                "&:hover": { bgcolor: "#ff9d33" },
              }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinRoom;
