import CustomizeCharacterCSS from "./CustomizeCharacter.module.css";
import { Button, Container, TextField, useMediaQuery } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CheckmarkIcon from "./Images/checkmark.png";
import { useContext, useEffect, useState } from "react";
import CustomizeCharacter from "./CustomizeCharacter";
import ModelRender from "./ModelRender/ModelRender";
import { BorderColor, Mode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";

const CustomizationColorGrid = ({ setType, currentColor, setCurrentColor }) => {
  // TODO: Remove line below when using CustomizeCharacter.js again
  const navigate = useNavigate();
  const [roomConnection] = useContext(RoomConnectionContext);

  const [itemData, setItemData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedColor, setSelectedColor] = useState(currentColor);

  useEffect(() => {
    setItemData(data);
  }, []);

  function compareNames(objectOne, objectTwo) {
    return objectOne.name === objectTwo.name;
  }

  const mediumScreen = useMediaQuery("(min-width:800px)");
  const largeScreen = useMediaQuery("(min-width:1200px)");

  let columns;
  if (largeScreen) columns = 8;
  else if (mediumScreen) columns = 5;
  else columns = 3;

  return (
    <>
      {/* <ModelRender /> */}

      <div className={CustomizeCharacterCSS["wrapper-background"]}>
        <div className={CustomizeCharacterCSS.items}>
          <TextField
            id="colorSelect"
            label="Search for a color..."
            variant="filled"
            size="Large"
            color="secondary"
            fullWidth
            sx={{
              width: "80%",
              margin: "0px 0px 25px 0px",
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          {selectedColor.name !== "none" ? (
            <div className={CustomizeCharacterCSS.text}>
              <h2>Color Select</h2>
              <h3>
                <i>{selectedColor.name}</i>
              </h3>
            </div>
          ) : (
            <div className={CustomizeCharacterCSS.text}>
              <h2>Selected Color</h2>
              <h3>
                <i>You have no color selected.</i>
              </h3>
            </div>
          )}
          <ImageList
            cols={columns}
            gap={8}
            className={CustomizeCharacterCSS.grid}
          >
            {itemData
              .filter((value) => {
                if (searchTerm === "") {
                  return value;
                } else if (
                  value.searchTags
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toString().toLowerCase())
                ) {
                  return value;
                } else if (
                  value.name
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toString().toLowerCase())
                ) {
                  return value;
                }
              })
              .map((item, index) => (
                <ImageListItem
                  key={index}
                  style={{
                    border: "5px solid black",
                    height: "fit-content",
                  }}
                >
                  <div
                    className={CustomizeCharacterCSS.gridColors}
                    onClick={() => setSelectedColor(item)}
                  >
                    <div
                      style={{
                        backgroundColor: `${item.colorOne}`,
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: `${item.colorTwo}`,
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: `${item.colorThree}`,
                      }}
                    />
                  </div>
                  {compareNames(selectedColor, item) === true && (
                    <img
                      src={CheckmarkIcon}
                      alt="LOGO"
                      className={CustomizeCharacterCSS.checkmark}
                    />
                  )}
                </ImageListItem>
              ))}
          </ImageList>
          <Button
            variant="contained"
            disableElevation
            size="large"
            sx={{
              bgcolor: "#ba68c8",
              "&:hover": { bgcolor: "#7b1fa2" },

              width: "80%",
            }}
            onClick={() => {
              setCurrentColor(selectedColor);
              //setType("none");

              roomConnection.sendCosmeticChangedEvent(selectedColor);

              // TODO: Remove line below when more customization options are available
              navigate("/play");
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="large"
            sx={{
              bgcolor: "#fa6666",
              "&:hover": { bgcolor: "#e63939" },

              width: "80%",
            }}
            onClick={() => {
              //setType("none");

              // TODO: Remove line below when more customization options are available
              navigate("/play");
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomizationColorGrid;

const data = [
  {
    colorOne: "#FF0000",
    colorTwo: "#FFA500",
    colorThree: "#FF00FF",
    searchTags: ["default", "red", "orange", "purple", "fiesta"],
    name: "Party Pinata",
  },
  {
    colorOne: "#2980B9",
    colorTwo: "#2980B9",
    colorThree: "#2980B9",
    searchTags: ["blue", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Blue",
  },
  {
    colorOne: "#E74C3C",
    colorTwo: "#E74C3C",
    colorThree: "#E74C3C",
    searchTags: ["red", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Red",
  },
  {
    colorOne: "#27AE60",
    colorTwo: "#27AE60",
    colorThree: "#27AE60",
    searchTags: ["green", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Green",
  },
  {
    colorOne: "#F39C12",
    colorTwo: "#F39C12",
    colorThree: "#F39C12",
    searchTags: ["orange", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Orange",
  },
  {
    colorOne: "#8E44AD",
    colorTwo: "#8E44AD",
    colorThree: "#8E44AD",
    searchTags: ["purple", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Purple",
  },
  {
    colorOne: "#F1C40F",
    colorTwo: "#F1C40F",
    colorThree: "#F1C40F",
    searchTags: ["yellow", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Yellow",
  },
  {
    colorOne: "#40E0D0",
    colorTwo: "#40E0D0",
    colorThree: "#40E0D0",
    searchTags: ["turquoise", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid Turquoise",
  },
  {
    colorOne: "#808080",
    colorTwo: "#808080",
    colorThree: "#808080",
    searchTags: [
      "gray",
      "grey",
      "solid",
      "monochrome",
      "uniform",
      "consistent",
    ],
    name: "Solid Gray",
  },
  {
    colorOne: "#fff",
    colorTwo: "#fff",
    colorThree: "#fff",
    searchTags: ["white", "solid", "monochrome", "uniform", "consistent"],
    name: "Solid White",
  },
  {
    colorOne: "#1ABC9C",
    colorTwo: "#E67E22",
    colorThree: "#F1C40F",
    searchTags: ["teal", "orange", "yellow", "sunset", "bright"],
    name: "Sunset Beach",
  },
  {
    colorOne: "#FF5733",
    colorTwo: "#DAF7A6",
    colorThree: "#C70039",
    searchTags: ["red", "green", "contrast", "festive", "cheerful"],
    name: "Festive Cheer",
  },
  {
    colorOne: "#2C3E50",
    colorTwo: "#34495E",
    colorThree: "#5D6D7E",
    searchTags: ["blue", "gray", "storm", "cool", "calm"],
    name: "Stormy Sky",
  },
  {
    colorOne: "#E67E22",
    colorTwo: "#1ABC9C",
    colorThree: "#2980B9",
    searchTags: ["orange", "teal", "blue", "tropical", "vibrant"],
    name: "Tropical Vibes",
  },
  {
    colorOne: "#34495E",
    colorTwo: "#2C3E50",
    colorThree: "#1A252F",
    searchTags: ["blue", "gray", "dark", "midnight", "cool"],
    name: "Midnight Blue",
  },
  {
    colorOne: "#3498DB",
    colorTwo: "#F1C40F",
    colorThree: "#E74C3C",
    searchTags: ["blue", "yellow", "red", "primary", "bold"],
    name: "Primary Pop",
  },
  {
    colorOne: "#3498DB",
    colorTwo: "#2ECC71",
    colorThree: "#1ABC9C",
    searchTags: ["blue", "green", "fresh", "vibrant", "cool"],
    name: "Aqua Splash",
  },
  {
    colorOne: "#F39C12",
    colorTwo: "#2980B9",
    colorThree: "#27AE60",
    searchTags: ["orange", "blue", "green", "sunset", "tropical"],
    name: "Summer Sunset",
  },
  {
    colorOne: "#8E44AD",
    colorTwo: "#3498DB",
    colorThree: "#2ECC71",
    searchTags: ["purple", "blue", "green", "garden", "vibrant"],
    name: "Garden Party",
  },
  {
    colorOne: "#C70039",
    colorTwo: "#2ECC71",
    colorThree: "#F39C12",
    searchTags: ["red", "green", "orange", "harvest", "vibrant"],
    name: "Autumn Harvest",
  },
  {
    colorOne: "#9B59B6",
    colorTwo: "#F1C40F",
    colorThree: "#3498DB",
    searchTags: ["purple", "yellow", "blue", "royal", "vibrant"],
    name: "Royal Garden",
  },
  {
    colorOne: "#F39C12",
    colorTwo: "#D35400",
    colorThree: "#E74C3C",
    searchTags: ["orange", "red", "vibrant", "fusion", "bold"],
    name: "Fiery Fusion",
  },
  {
    colorOne: "#F1C40F",
    colorTwo: "#E67E22",
    colorThree: "#D35400",
    searchTags: ["yellow", "orange", "bright", "citrus", "warm"],
    name: "Citrus Burst",
  },
  {
    colorOne: "#1ABC9C",
    colorTwo: "#16A085",
    colorThree: "#0E6655",
    searchTags: ["green", "emerald", "calm", "isle", "fresh"],
    name: "Emerald Isle",
  },
  {
    colorOne: "#34495E",
    colorTwo: "#E74C3C",
    colorThree: "#F1C40F",
    searchTags: ["blue", "red", "yellow", "bold", "adventure"],
    name: "Bold Adventure",
  },
  {
    colorOne: "#3498DB",
    colorTwo: "#E74C3C",
    colorThree: "#9B59B6",
    searchTags: ["blue", "red", "purple", "electric", "vibrant"],
    name: "Electric Mix",
  },
  {
    colorOne: "#FF5733",
    colorTwo: "#C70039",
    colorThree: "#581845",
    searchTags: ["red", "wine", "deep", "bold", "elegant"],
    name: "Crimson Affair",
  },
  {
    colorOne: "#27AE60",
    colorTwo: "#2ECC71",
    colorThree: "#F39C12",
    searchTags: ["green", "orange", "yellow", "vivid", "bright"],
    name: "Spring Bloom",
  },
  {
    colorOne: "#1A5276",
    colorTwo: "#2980B9",
    colorThree: "#5499C7",
    searchTags: ["blue", "navy", "cool", "marine", "deep"],
    name: "Nautical Dream",
  },
  {
    colorOne: "#2C3E50",
    colorTwo: "#1F618D",
    colorThree: "#34495E",
    searchTags: ["blue", "gray", "dark", "stormy", "cool"],
    name: "Deep Twilight",
  },
  {
    colorOne: "#E74C3C",
    colorTwo: "#E67E22",
    colorThree: "#F1C40F",
    searchTags: ["red", "orange", "yellow", "sunrise", "warm"],
    name: "Warm Sunrise",
  },
  {
    colorOne: "#7DCEA0",
    colorTwo: "#76D7C4",
    colorThree: "#73C6B6",
    searchTags: ["green", "mint", "fresh", "soft", "gentle"],
    name: "Mint Delight",
  },
  {
    colorOne: "#F5B041",
    colorTwo: "#DC7633",
    colorThree: "#BA4A00",
    searchTags: ["orange", "bright", "vibrant", "fiery", "warm"],
    name: "Amber Flame",
  },
  {
    colorOne: "#196F3D",
    colorTwo: "#27AE60",
    colorThree: "#2ECC71",
    searchTags: ["green", "nature", "fresh", "vibrant", "earth"],
    name: "Nature's Embrace",
  },
  {
    colorOne: "#D98880",
    colorTwo: "#C39BD3",
    colorThree: "#A569BD",
    searchTags: ["pink", "purple", "elegant", "feminine", "regal"],
    name: "Elegant Blush",
  },
  {
    colorOne: "#FDEBD0",
    colorTwo: "#A9DFBF",
    colorThree: "#48C9B0",
    searchTags: ["pastel", "turquoise", "green", "soft", "calm"],
    name: "Soft Aqua",
  },
  {
    colorOne: "#BB8FCE",
    colorTwo: "#7D3C98",
    colorThree: "#4A235A",
    searchTags: ["lavender", "purple", "violet", "regal", "dark"],
    name: "Royal Velvet",
  },
  {
    colorOne: "#AF601A",
    colorTwo: "#CB9E28",
    colorThree: "#EAB543",
    searchTags: ["brown", "warm", "earthy", "rustic", "neutral"],
    name: "Rustic Charm",
  },
  {
    colorOne: "#D98880",
    colorTwo: "#EC7063",
    colorThree: "#F5B7B1",
    searchTags: ["pink", "soft", "gentle", "delicate", "pastel"],
    name: "Delicate Pink",
  },
  {
    colorOne: "#F4D03F",
    colorTwo: "#FAD7A0",
    colorThree: "#FDEBD0",
    searchTags: ["yellow", "pastel", "soft", "warm", "calm"],
    name: "Soft Yellow",
  },
  {
    colorOne: "#FF5733",
    colorTwo: "#33FF57",
    colorThree: "#3357FF",
    searchTags: ["red", "green", "blue", "contrasting", "vivid"],
    name: "Vivid Vibrance",
  },
  {
    colorOne: "#FFD700",
    colorTwo: "#00BFFF",
    colorThree: "#8A2BE2",
    searchTags: ["gold", "sky blue", "blue violet", "bright", "bold"],
    name: "Bold Brilliance",
  },
  {
    colorOne: "#FF4500",
    colorTwo: "#2E8B57",
    colorThree: "#1E90FF",
    searchTags: [
      "orange red",
      "sea green",
      "dodger blue",
      "dynamic",
      "eye-catching",
    ],
    name: "Dynamic Trio",
  },
  {
    colorOne: "#FFC300",
    colorTwo: "#DAF7A6",
    colorThree: "#900C3F",
    searchTags: ["yellow", "light green", "maroon", "fresh", "unique"],
    name: "Fresh Bloom",
  },
  {
    colorOne: "#581845",
    colorTwo: "#C70039",
    colorThree: "#FFC300",
    searchTags: ["dark purple", "red", "yellow", "striking", "intense"],
    name: "Intense Strikes",
  },
  {
    colorOne: "#28B463",
    colorTwo: "#C0392B",
    colorThree: "#F4D03F",
    searchTags: ["green", "crimson", "gold", "bold", "vibrant"],
    name: "Vibrant Harmony",
  },
  {
    colorOne: "#6C3483",
    colorTwo: "#3498DB",
    colorThree: "#F1C40F",
    searchTags: ["purple", "blue", "yellow", "lively", "sharp"],
    name: "Sharp Liveliness",
  },
  {
    colorOne: "#2ECC71",
    colorTwo: "#E74C3C",
    colorThree: "#3498DB",
    searchTags: ["green", "red", "blue", "contrast", "bright"],
    name: "Bright Contrast",
  },
  {
    colorOne: "#F39C12",
    colorTwo: "#8E44AD",
    colorThree: "#2980B9",
    searchTags: ["orange", "purple", "blue", "colorful", "distinctive"],
    name: "Distinctive Colors",
  },
  {
    colorOne: "#D35400",
    colorTwo: "#16A085",
    colorThree: "#2980B9",
    searchTags: ["orange", "teal", "blue", "earthy", "natural"],
    name: "Natural Earth",
  },
  {
    colorOne: "#2C3E50",
    colorTwo: "#E67E22",
    colorThree: "#ECF0F1",
    searchTags: ["dark blue", "orange", "light grey", "modern", "sleek"],
    name: "Modern Sleek",
  },
  {
    colorOne: "#9B59B6",
    colorTwo: "#F1C40F",
    colorThree: "#E74C3C",
    searchTags: ["purple", "yellow", "red", "bright", "bold"],
    name: "Party Parade",
  },
  {
    colorOne: "#34495E",
    colorTwo: "#1ABC9C",
    colorThree: "#F39C12",
    searchTags: ["navy blue", "turquoise", "orange", "dynamic", "sharp"],
    name: "Dynamic Sharp",
  },
  {
    colorOne: "#F1C40F",
    colorTwo: "#2980B9",
    colorThree: "#8E44AD",
    searchTags: ["yellow", "blue", "purple", "vivid", "striking"],
    name: "Striking Vividness",
  },
  {
    colorOne: "#7D3C98",
    colorTwo: "#16A085",
    colorThree: "#F39C12",
    searchTags: ["purple", "teal", "orange", "unique", "fresh"],
    name: "Fresh Uniqueness",
  },
  {
    colorOne: "#1ABC9C",
    colorTwo: "#E74C3C",
    colorThree: "#F1C40F",
    searchTags: ["teal", "red", "yellow", "bright", "bold"],
    name: "Bold Brightness",
  },
  {
    colorOne: "#3498DB",
    colorTwo: "#E67E22",
    colorThree: "#2ECC71",
    searchTags: ["blue", "orange", "green", "vivid", "contrasting"],
    name: "Vivid Contrast",
  },
  {
    colorOne: "#2980B9",
    colorTwo: "#F39C12",
    colorThree: "#8E44AD",
    searchTags: ["blue", "orange", "purple", "dynamic", "striking"],
    name: "Striking Dynamic",
  },
  {
    colorOne: "#8E44AD",
    colorTwo: "#1ABC9C",
    colorThree: "#F1C40F",
    searchTags: ["purple", "teal", "yellow", "bright", "distinctive"],
    name: "Distinctive Brightness",
  },
  {
    colorOne: "#E74C3C",
    colorTwo: "#3498DB",
    colorThree: "#F1C40F",
    searchTags: ["red", "blue", "yellow", "vivid", "sharp"],
    name: "Sharp Vividness",
  },
  {
    colorOne: "#2ECC71",
    colorTwo: "#E67E22",
    colorThree: "#8E44AD",
    searchTags: ["green", "orange", "purple", "dynamic", "striking"],
    name: "Dynamic Strikes",
  },
  {
    colorOne: "#008080",
    colorTwo: "#FF6347",
    colorThree: "#4682B4",
    searchTags: ["teal", "tomato", "steel blue", "refreshing", "cool"],
    name: "Cool Breeze",
  },
  {
    colorOne: "#4682B4",
    colorTwo: "#B0C4DE",
    colorThree: "#708090",
    searchTags: [
      "steel blue",
      "light steel blue",
      "slate gray",
      "calm",
      "cool",
    ],
    name: "Steel Calm",
  },
  {
    colorOne: "#FF6347",
    colorTwo: "#40E0D0",
    colorThree: "#FFD700",
    searchTags: ["tomato", "turquoise", "gold", "vibrant", "sunny"],
    name: "Sunny Day",
  },
  {
    colorOne: "#2F4F4F",
    colorTwo: "#696969",
    colorThree: "#A9A9A9",
    searchTags: ["dark slate gray", "dim gray", "dark gray", "storm", "cool"],
    name: "Gray Storm",
  },
  {
    colorOne: "#FF4500",
    colorTwo: "#228B22",
    colorThree: "#6A5ACD",
    searchTags: ["orange red", "forest green", "purple", "vibrant", "fresh"],
    name: "Vibrant Freshness",
  },
  {
    colorOne: "#4169E1",
    colorTwo: "#FFD700",
    colorThree: "#FF6347",
    searchTags: ["royal blue", "gold", "tomato", "bright", "bold"],
    name: "Royal Bright",
  },
  {
    colorOne: "#4169E1",
    colorTwo: "#228B22",
    colorThree: "#40E0D0",
    searchTags: ["royal blue", "forest green", "turquoise", "fresh", "vibrant"],
    name: "Fresh Vibrance",
  },
  {
    colorOne: "#FF8C00",
    colorTwo: "#6A5ACD",
    colorThree: "#2F4F4F",
    searchTags: ["dark orange", "purple", "dark slate gray", "vibrant", "deep"],
    name: "Deep Vibrance",
  },
  {
    colorOne: "#9932CC",
    colorTwo: "#4169E1",
    colorThree: "#228B22",
    searchTags: [
      "dark orchid",
      "royal blue",
      "forest green",
      "garden",
      "fresh",
    ],
    name: "Garden Freshness",
  },
  {
    colorOne: "#8B0000",
    colorTwo: "#228B22",
    colorThree: "#FF8C00",
    searchTags: [
      "dark red",
      "forest green",
      "dark orange",
      "vibrant",
      "harvest",
    ],
    name: "Harvest Vibrance",
  },
  {
    colorOne: "#9370DB",
    colorTwo: "#FFD700",
    colorThree: "#4169E1",
    searchTags: ["medium purple", "gold", "royal blue", "regal", "bright"],
    name: "Regal Bright",
  },
  {
    colorOne: "#FF8C00",
    colorTwo: "#FF4500",
    colorThree: "#8B0000",
    searchTags: ["dark orange", "orange red", "dark red", "bold", "intense"],
    name: "Intense Boldness",
  },
  {
    colorOne: "#FFD700",
    colorTwo: "#FF6347",
    colorThree: "#FF4500",
    searchTags: ["gold", "tomato", "orange red", "warm", "bright"],
    name: "Warm Brightness",
  },
  {
    colorOne: "#778899",
    colorTwo: "#8B0000",
    colorThree: "#FFD700",
    searchTags: ["light slate gray", "dark red", "gold", "bold", "adventure"],
    name: "Adventurous Bold",
  },
  {
    colorOne: "#4169E1",
    colorTwo: "#8B0000",
    colorThree: "#9932CC",
    searchTags: [
      "royal blue",
      "dark red",
      "dark orchid",
      "vibrant",
      "electric",
    ],
    name: "Electric Vibrance",
  },
  {
    colorOne: "#FF4500",
    colorTwo: "#8B0000",
    colorThree: "#4B0082",
    searchTags: ["orange red", "dark red", "indigo", "deep", "elegant"],
    name: "Deep Elegance",
  },
  {
    colorOne: "#228B22",
    colorTwo: "#40E0D0",
    colorThree: "#FF8C00",
    searchTags: ["forest green", "turquoise", "dark orange", "bright", "vivid"],
    name: "Vivid Brightness",
  },
  {
    colorOne: "#1E90FF",
    colorTwo: "#4682B4",
    colorThree: "#87CEFA",
    searchTags: [
      "dodger blue",
      "steel blue",
      "light sky blue",
      "cool",
      "marine",
    ],
    name: "Marine Cool",
  },
  {
    colorOne: "#2F4F4F",
    colorTwo: "#008080",
    colorThree: "#696969",
    searchTags: ["dark slate gray", "teal", "dim gray", "cool", "dark"],
    name: "Dark Coolness",
  },
  {
    colorOne: "#8B0000",
    colorTwo: "#FF8C00",
    colorThree: "#FFD700",
    searchTags: ["dark red", "dark orange", "gold", "warm", "sunrise"],
    name: "Sunrise Warmth",
  },
  {
    colorOne: "#90EE90",
    colorTwo: "#98FB98",
    colorThree: "#00FA9A",
    searchTags: [
      "light green",
      "pale green",
      "medium spring green",
      "fresh",
      "soft",
    ],
    name: "Spring Freshness",
  },
  {
    colorOne: "#FF7F50",
    colorTwo: "#CD5C5C",
    colorThree: "#B22222",
    searchTags: ["coral", "indian red", "firebrick", "warm", "bold"],
    name: "Bold Warmth",
  },
  {
    colorOne: "#4682B4",
    colorTwo: "#5F9EA0",
    colorThree: "#B0E0E6",
    searchTags: ["steel blue", "cadet blue", "powder blue", "cool", "calm"],
    name: "Cool Serenity",
  },
];
