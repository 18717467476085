import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./MainUI/Login/Login";
import Register from "./MainUI/Register";
import Play from "./MainUI/Play";
import WaitingToStart from "./MainUI/WaitingToStart/WaitingToStart";
import JoinRoom from "./MainUI/JoinRoom/JoinRoom";
import { RoomConnectionProvider } from "./RoomConnection/RoomConnectionProvider";
import BulkFiestamal from "./Debug/BulkFiestamal";
import CustomizeCharacter from "./MainUI/CustomizeCharacter/CustomizeCharacter";
import Draw from "./Minigames/Draw/Draw";
import { useLocation } from "react-router-dom";
import NavBar from "./Misc/NavBar";

function App() {
  const location = useLocation();

  console.log("location: " + location.pathname);

  return (
    <>
      {location.pathname === "/play" && <NavBar />}
      <RoomConnectionProvider>
        <Routes>
          <Route path="/" element={<JoinRoom />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/play" element={<Play />} />
          <Route path="/customize" element={<CustomizeCharacter />} />
          <Route path="/debug/bulkfiestamals" element={<BulkFiestamal />} />
          <Route path="/debug" element={<Draw />} />
        </Routes>
      </RoomConnectionProvider>
    </>
  );
}

export default App;
