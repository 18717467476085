import { useNavigate } from "react-router-dom";
import WaitingToStartCSS from "./WaitingToStart.module.css";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";

const WaitingToStart = () => {
  const navigate = useNavigate();
  const [roomConnection] = useContext(RoomConnectionContext);

  const [isHost, setIsHost] = useState(roomConnection?.isHost ?? false);

  useEffect(() => {
    roomConnection?.addCustomListener(
      "PlayerData",
      onUpdatePlayerData,
      "waitingToStart"
    );
    roomConnection?.dispatchQueuedEvents("waitingToStart");

    return () => {
      roomConnection?.removeCustomListener("PlayerData", onUpdatePlayerData);
    };
  }, [roomConnection]);

  function onUpdatePlayerData(event) {
    let playerData = event.detail;
    if (playerData?.isHost !== undefined) setIsHost(playerData.isHost);

    roomConnection.isHost = playerData.isHost;
  }

  return (
    <>
      <div className={WaitingToStartCSS["wrapper-background"]}>
        <div className={WaitingToStartCSS.items}>
          <h1 className={WaitingToStartCSS.h1}>You're in!</h1>
          {isHost === false ? (
            <h2 className={WaitingToStartCSS.h2}>
              We are waiting on the host to start the game.
            </h2>
          ) : (
            <>
              <h2 className={WaitingToStartCSS.h2}>
                You are the host. When everyone is ready, press start.
              </h2>
            </>
          )}
        </div>
        <div className={WaitingToStartCSS.button}>
          {isHost === true && (
            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                "&.MuiButton-contained": {
                  color: "#000000",
                },

                fontWeight: 700,
                bgcolor: "#ba68c8",
                "&:hover": { bgcolor: "#7b1fa2" },
              }}
              onClick={() => roomConnection.sendButtonClickedEvent("start")}
            >
              Start Game
            </Button>
          )}
          <Button
            size="large"
            variant="contained"
            disableElevation
            sx={{
              "&.MuiButton-contained": {
                color: "#000000",
              },

              fontWeight: 700,
              bgcolor: "#ffad54",
              "&:hover": { bgcolor: "#ff9d33" },
            }}
            onClick={() => navigate("/customize")}
          >
            Customize Character
          </Button>
        </div>
      </div>
    </>
  );
};

export default WaitingToStart;
