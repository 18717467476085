import { useContext, useEffect, useState } from "react";
import CustomizeCharacterCSS from "./CustomizeCharacter.module.css";
import CustomizationGrid from "./CustomizationGrid";
import CustomizationColorGrid from "./CustomizationColorGrid";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";
import { AspectRatio } from "@mui/icons-material";

const CustomizeCharacter = () => {
  const [roomConnection] = useContext(RoomConnectionContext);
  const navigate = useNavigate();

  const DefaultColor = {
    colorOne: "#FF0000",
    colorTwo: "#FFA500",
    colorThree: "#FF00FF",
    searchTags: ["default", "red", "orange", "purple", "fiesta"],
    name: "Party Pinata",
  };

  const [currentColor, setCurrentColor] = useState(
    roomConnection?.currentColor || DefaultColor
  );

  // TODO: Once more customization options are available, set default state to "none"
  const [customizePage, setCustomizePage] = useState("color");

  return (
    <>
      <div className={CustomizeCharacterCSS["wrapper-background"]}>
        {customizePage === "none" && (
          <div className={CustomizeCharacterCSS.items}>
            <div
              className={CustomizeCharacterCSS.customizeOption}
              onClick={() => {
                setCustomizePage("color");
              }}
            >
              <div
                style={{
                  backgroundColor: `${currentColor.colorOne}`,
                }}
              />
              <div
                style={{
                  backgroundColor: `${currentColor.colorTwo}`,
                }}
              />
              <div
                style={{
                  backgroundColor: `${currentColor.colorThree}`,
                }}
              />
            </div>

            <Button
              size="large"
              variant="contained"
              disableElevation
              sx={{
                "&.MuiButton-contained": {
                  color: "#000000",
                },
                bgcolor: "#ffad54",
                "&:hover": { bgcolor: "#ff9d33" },

                width: "20%",
                margin: "100px 0px 0px 0px",
              }}
              onClick={() => navigate("/play")}
            >
              Back
            </Button>
          </div>
        )}
      </div>

      {customizePage === "color" && (
        <CustomizationColorGrid
          setType={setCustomizePage}
          currentColor={currentColor}
          setCurrentColor={setCurrentColor}
        />
      )}
      {customizePage !== "none" && customizePage !== "color" && (
        <CustomizationGrid type={customizePage} setType={setCustomizePage} />
      )}
    </>
  );
};

export default CustomizeCharacter;
