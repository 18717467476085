import { useContext, useEffect, useState } from "react";
import PlayerOutCSS from "./PlayerOut.module.css";
import { useNavigate } from "react-router-dom";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";

const PlayerOut = () => {
  const [roomConnection] = useContext(RoomConnectionContext);
  const navigate = useNavigate();

  const [hasFutureRole, setHasFutureRole] = useState(false);

  function onUpdatePlayerData(event) {
    let playerData = event.detail;
    if (playerData?.hasFutureRole !== undefined)
      setHasFutureRole(playerData.hasFutureRole);
  }

  useEffect(() => {
    roomConnection?.addCustomListener(
      "PlayerData",
      onUpdatePlayerData,
      "playerOut"
    );
    roomConnection?.dispatchQueuedEvents("playerOut");

    return () => {
      roomConnection?.removeCustomListener("PlayerData", onUpdatePlayerData);
    };
  }, [roomConnection]);

  return (
    <>
      <div className={PlayerOutCSS["wrapper-background"]}>
        <div className={PlayerOutCSS.items}>
          <h1 className={PlayerOutCSS.headings}>You're Out!</h1>
          <h3 className={PlayerOutCSS.headings}>
            <i>
              {hasFutureRole
                ? "You will respawn in the next game."
                : "Don't look away just yet. There's more for you in this minigame!"}
            </i>
          </h3>
        </div>
      </div>
    </>
  );
};

export default PlayerOut;
