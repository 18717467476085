import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const NavBar = () => {
  const roomCode = "ABC123";
  const nickname = "Player1";
  const score = 100;

  return (
    <AppBar position="static" sx={{ zIndex: 1400, height: "64px" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="caption" component="div">
            Room Code:
          </Typography>
          <Typography variant="h6" component="div">
            {roomCode}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="caption" component="div">
            Nickname
          </Typography>
          <Typography variant="h6" component="div">
            {nickname}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: "right" }}>
          <Typography variant="caption" component="div">
            Score:
          </Typography>
          <Typography variant="h6" component="div">
            {score}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
