import '../../App.css';

const HaltNHustle = () => {

    return (
        <>
        <div className='wrapper-haltnhustle'>

        

        </div>
        </>
    )
}

export default HaltNHustle;