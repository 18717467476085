import TapForNextRoundCSS from "./TapForNextRound.module.css";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";
import { useContext } from "react";

const TapForNextRound = () => {
  const [roomConnection] = useContext(RoomConnectionContext);

  return (
    <>
      <div
        className={TapForNextRoundCSS["wrapper-background"]}
        onClick={() => {
          roomConnection.sendButtonClickedEvent("hit");
        }}
      >
        <h1
          className={TapForNextRoundCSS.items}
          style={{
            textAlign: "center",
            fontSize: "48px",
            color: "white",
          }}
        >
          Tap to Break the Pinata!
        </h1>
      </div>
    </>
  );
};

export default TapForNextRound;
