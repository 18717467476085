let peerConnection;
let wsConnection;

async function joinGameLobby(lobbyId, nickname) {
    return new Promise(async (resolve, reject) => {
        const peerConnectionConfig = await JSON.parse(process.env.REACT_APP_ICE);
        peerConnectionConfig.iceTransportPolicy = 'all';
        wsConnection = new WebSocket(`wss://fiestagame.ca/signalling-server?type=player&lobbyId=${lobbyId}&nickname=${nickname}`);
        wsConnection.onmessage = onWSMessage;
        peerConnection = new RTCPeerConnection(peerConnectionConfig);
        peerConnection.onicecandidate = handleIceCandidate;
        peerConnection.ondatachannel = (event) => {
            // Player successfully joined lobby
            resolve(event.channel);
        };
        wsConnection.onclose = function(event) {
            reject(event);
        }
        wsConnection.ontimeout = function(event) {
            reject(event);
        }
    });
}

let wsMessageHandlers = new Map(); // messageType => messageHandler

wsMessageHandlers.set("offer", async (offer) => {
    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
    let answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    messageLobby(peerConnection.currentLocalDescription);
});

wsMessageHandlers.set("iceCandidate", (iceCandidate) => {
    const candidate = new RTCIceCandidate(iceCandidate.candidate);
    peerConnection.addIceCandidate(candidate).catch(errorHandler);
});

function onWSMessage(msgRaw) {
    let msg;
    try {
        msg = JSON.parse(msgRaw.data);
    } catch (err) {
        wsConnection.close(1008, `Cannot parse lobby message`)
        return;
    }

    let handler = wsMessageHandlers.get(msg.type);
    if (!handler || (typeof handler != 'function')) {
        console.warn(`Unsupported lobby message type: ${msg.type}`);
        return;
    }
    handler(msg);
}

function messageLobby(message) {
    let msg = JSON.stringify(message);
    wsConnection.send(msg);
}

function errorHandler(error) {
    console.warn(error);
}

function handleIceCandidate(event) {
    if (event.candidate && event.candidate.candidate) {
        let candidatePayload = {
            type: "iceCandidate",
            candidate: event.candidate
        }
        messageLobby(candidatePayload);
    }
}

export {joinGameLobby}