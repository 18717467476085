import "../App.css";
import Bomba from "../Minigames/Bomba/Bomba";
import DropNDash from "../Minigames/DropNDash/DropNDash";
import HaltNHustle from "../Minigames/HaltNHustle/HaltNHustle";
import Draw from "../Minigames/Draw/Draw";
import WaitingToStart from "./WaitingToStart/WaitingToStart";
import PlayerOut from "../Minigames/PlayerOut/PlayerOut";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RoomConnectionContext from "../RoomConnection/RoomConnectionProvider";
import TapForNextRound from "../Minigames/Scoreboard/TapForNextRound";
import LookUp from "./LookUp/LookUp";

const Play = () => {
  const location = useLocation();
  const [minigame, setMinigame] = useState(
    location.state?.minigame || "waitingToStart"
  );
  const [roomConnection] = useContext(RoomConnectionContext);
  const minigames = {
    waitingToStart: <WaitingToStart />,
    hitPinata: <TapForNextRound />,
    lookUp: <LookUp />,
    bomba: <Bomba />,
    dropNDash: <DropNDash />,
    haltNHustle: <HaltNHustle />,
    draw: <Draw />,
    playerOut: <PlayerOut />,
  };

  useEffect(() => {
    if (location.state?.minigame) {
      setMinigame(location.state.minigame);
    }
  }, [location.state]);

  const onChangeMinigame = (event) => {
    setMinigame(event.detail.minigame);
  };

  useEffect(() => {
    roomConnection?.addEventListener("ChangeMinigame", onChangeMinigame);

    return () => {
      roomConnection?.removeEventListener("ChangeMinigame", onChangeMinigame);
    };
  }, [roomConnection]);

  return <>{minigames[minigame]}</>;
};

export default Play;
