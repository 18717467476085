import CustomizeCharacterCSS from "./CustomizeCharacter.module.css";
import { Button } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useState } from "react";

const CustomizationGrid = ({ type, setType }) => {
  const [itemData, setItemData] = useState([]);

  return (
    <>
      <div className={CustomizeCharacterCSS["wrapper-background"]}>
        <h1>Im adjusting {type}</h1>

        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Button
          variant="contained"
          disableElevation
          sx={{
            bgcolor: "#ba68c8",
            "&:hover": { bgcolor: "#7b1fa2" },
          }}
          onClick={() => {
            setType("none");
          }}
        >
          Confirm
        </Button>

        <Button
          variant="contained"
          disableElevation
          sx={{
            bgcolor: "#ba68c8",
            "&:hover": { bgcolor: "#7b1fa2" },
          }}
          onClick={() => setType("none")}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default CustomizationGrid;
