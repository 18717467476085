import { useContext, useEffect, useState } from "react";
import BombaCSS from "./Bomba.module.css";
import passImage from "./Images/PASS.png";
import passOffImage from "./Images/PASS_off.png";
import heartImage from "./Images/heart.png";
import heartOffImage from "./Images/heart_off.png";
import RoomConnectionContext from "../../RoomConnection/RoomConnectionProvider";
import { useNavigate } from "react-router-dom";

const Bomba = () => {
  const [passImg, setPassImg] = useState(passOffImage);
  const [hearts, setHearts] = useState([]);
  const [maxLivesState, setMaxLives] = useState(0);
  const [livesState, setLives] = useState(0);
  const [isPlayersTurn, setIsPlayersTurn] = useState(false);
  const [roomConnection] = useContext(RoomConnectionContext);
  const navigate = useNavigate();

  useEffect(() => {
    roomConnection?.addCustomListener(
      "PlayerData",
      onUpdatePlayerData,
      "bomba"
    );
    roomConnection?.dispatchQueuedEvents("bomba");

    return () => {
      roomConnection?.removeCustomListener("PlayerData", onUpdatePlayerData);
    };
  }, [roomConnection]);

  useEffect(() => {
    setHearts(fillHearts());
  }, [livesState]);

  useEffect(() => {
    setLives(maxLivesState);
  }, [maxLivesState]);

  useEffect(() => {
    if (isPlayersTurn) {
      setPassImg(passImage);
    } else {
      setPassImg(passOffImage);
    }
  }, [isPlayersTurn]);

  function onUpdatePlayerData(event) {
    let playerData = event.detail;
    if (playerData?.lives !== undefined) setLives(playerData.lives);
    if (playerData?.isPlayersTurn !== undefined)
      setIsPlayersTurn(playerData.isPlayersTurn);
    if (playerData?.maxLives) setMaxLives(playerData.maxLives);
  }

  // Set array of hearts to be displayed to the screen
  function fillHearts() {
    let newHearts = [];

    // Set all lives to full hearts
    for (let i = 0; i < livesState; i++) {
      newHearts[i] = heartImage;
    }

    // Fill empty hearts for missing lives
    for (let i = livesState; i < maxLivesState; i++) {
      newHearts[i] = heartOffImage;
    }

    return newHearts;
  }

  const handleClick = () => {
    if (!isPlayersTurn) return;
    roomConnection.sendButtonClickedEvent("bomb");
  };

  return (
    <>
      <div className={BombaCSS["wrapper-background"]}>
        <div className={BombaCSS.items}>
          <img id="bomb" src={passImg} alt="PASS IMG" onClick={handleClick} />

          <div className={BombaCSS.hearts}>
            {hearts.map((heart, index) => (
              <img key={index} src={heart} alt="HEART IMG" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Bomba;
