import { createContext, useState } from "react";

const RoomConnectionContext = createContext(null);

export const RoomConnectionProvider = ({ children }) => {
  const [roomConnection, setRoomConnection] = useState(null);

  return (
    <RoomConnectionContext.Provider value={[ roomConnection, setRoomConnection ]}>
      {children}
    </RoomConnectionContext.Provider>
  );
}

export default RoomConnectionContext;