import '../App.css';

const Register = () => {

    return (
        <>
        <div className='wrapper-register'>

        

        </div>
        </>
    )
}

export default Register;