import LoginCSS from "./Login.module.css";
import { TextField, Button } from "@mui/material";
import FiestaLogo from "../../Images/fiesta_logo.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={LoginCSS["wrapper-background"]}>
        <div className={LoginCSS.items}>
          <img
            src={FiestaLogo}
            alt="LOGO"
            style={{
              margin: "100px 0px 150px 0px",
            }}
          />

          <div
            className={LoginCSS.formSection}
            style={{
              margin: "0px 0px 50px 0px",
            }}
          >
            <TextField
              id="username"
              label="Enter Username"
              variant="filled"
              fullWidth
              color="secondary"
              sx={{
                "& .MuiFilledInput-root": {
                  color: "#000",
                  "&:before": {
                    borderColor: "#d86ff2",
                    borderWidth: "2px",
                  },
                },
                "& .MuiInputLabel-filled": {
                  color: "#2e2e2e",
                },
              }}
            />

            <div className={LoginCSS.connectedElements}>
              <TextField
                id="password"
                label="Enter Password"
                variant="filled"
                fullWidth
                color="secondary"
                sx={{
                  "& .MuiFilledInput-root": {
                    color: "#000",
                    "&:before": {
                      borderColor: "#d86ff2",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputLabel-filled": {
                    color: "#2e2e2e",
                  },
                }}
              />

              <u
                className={LoginCSS.forgotPassword}
                onClick={() => console.log("Forgot password was pressed")}
              >
                Forgot password?
              </u>
            </div>

            <div className={LoginCSS.connectedElements}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                size="large"
                sx={{
                  bgcolor: "#fa6666",
                  "&:hover": { bgcolor: "#e63939" },
                }}
              >
                Login
              </Button>

              <FormControlLabel
                control={<Checkbox color="secondary" defaultChecked />}
                label="Remember me"
              />
            </div>
          </div>

          <div
            className={LoginCSS.formSection}
            style={{
              margin: "10px 0px 0px 0px",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                bgcolor: "#ffad54",
                "&:hover": { bgcolor: "#ff9d33" },
              }}
              onClick={() => navigate("/register")}
            >
              Register
            </Button>

            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                bgcolor: "#ba68c8",
                "&:hover": { bgcolor: "#7b1fa2" },
              }}
              onClick={() => navigate("/")}
            >
              Continue as Guest
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
